


































































import { Component, Vue } from "vue-property-decorator";
import {AttachmentHostType, CustomFormType, FundDto, FundProjectDto, StudentDto} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import CustomFormDetailView from "@/components/CustomForm/FormDetail/index.vue";
@Component({
  name: "fundProjectDetail",
  components: {
    ExportWord,
    AttachmentsView,
    CustomFormDetailView,
  },
})
export default class FundProjectDetail extends Vue {
  dataId?: number;
  detail: FundProjectDto = {};
  loadingProfile = true;

  get hostType() {
    return CustomFormType.FundProject;
  }

  created() {
    this.dataId = Number(this.$route.params.id);
  }

  getDetail() {
    return new Promise<FundProjectDto>((resolve, reject) => {
      if (!this.loadingProfile) {
        resolve(this.detail);
      } else {
        api.fundProject.get({id: this.dataId}).then((res) => {
          this.detail = res;
          this.loadingProfile = false;
          resolve(res);
        });
      }
    });
  }
}
